/*------------- #404-PAGE --------------*/


.content-page-404 {
  padding: 230px 0 510px;
  background: url("../img/404-img.png") 0 100% no-repeat;
  text-align: center;
  background-size: contain;
  background-color: #F7F9F9;

  @media (max-width: 800px) {
    padding: 100px 0 300px;
  }

  @media (max-width: 360px) {
    padding: 50px 0 250px;
  }

  .title {
    font-size: 257px;
    margin-bottom: 15px;
    line-height: 1;

    @media (max-width: 800px) {
      font-size: 150px;
    }

    @media (max-width: 480px) {
      font-size: 100px;
    }
  }

  .subtitle {
    font-size: 47px;
    margin-bottom: 30px;
    line-height: 1;

    @media (max-width: 800px) {
      font-size: 36px;
    }
  }

  .text {
    font-size: 28px;
    margin-bottom: 53px;
    line-height: 1.3;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }

  .btn {
    font-size: 32px;
    text-transform: uppercase;
  }
}